import TitleStart from './asked/titleStatrt/titleStart';

import style from './appStyle.module.scss';

export const App = () => {

  
  return (
    <>
      <div className={style.container}>
        
        <TitleStart />

      </div>
    </>
  );
};
